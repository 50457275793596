import { Box, Container, ContainerProps } from "@mui/material";
import React from "react";

interface ContentProps extends React.PropsWithChildren, ContainerProps {}

const Content: React.FC<ContentProps> = (props) => {
  const { children, ...containerProps } = props;
  return (
    <Container {...containerProps} className="bg" maxWidth={false}>
      <div className="pat"></div>
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          overflowX: "hidden",
          overflowY: "auto",
          top: 0,
          left: 0,
          zIndex: 1,
        }}
      >
        {children}
      </Box>
    </Container>
  );
};

export default Content;
