import { AppBarProps, Box, Toolbar } from "@mui/material";
import React, { MouseEvent, useCallback, useContext } from "react";
import { LoginBtn, RegisterBtn } from "src/assets";
import { AppContext } from "src/main/constants";
import useGtmTracker, { EvtAction } from "src/main/hooks/useGtmTracker";
import { createStyles, isMobile } from "src/main/utils";

interface TopBarProps extends AppBarProps {}

const TopBar: React.FC<TopBarProps> = (props) => {
  const { sendEvent } = useGtmTracker();
  const { urls } = useContext(AppContext);
  const { loginUrl, registerUrl } = urls ?? {};

  const handleClick = useCallback(
    (type: "login" | "register") => (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      if (type === "login") {
        sendEvent(EvtAction.ClickLogin);
        window.open(
          loginUrl,
          "_blank",
          isMobile() ? "width=full,height=full" : ""
        );
      } else {
        sendEvent(EvtAction.ClickRegister);
        window.open(
          registerUrl,
          "_blank",
          isMobile() ? "width=full,height=full" : ""
        );
      }
    },
    [loginUrl, registerUrl, sendEvent]
  );

  return (
    <Toolbar disableGutters sx={styles.toolbar}>
      <Box
        component="a"
        href={loginUrl}
        onClick={handleClick("login")}
        target="_blank"
        sx={styles.loginBtn}
      ></Box>
      <Box
        component="a"
        href={registerUrl}
        onClick={handleClick("register")}
        target="_blank"
        sx={styles.registerBtn}
      ></Box>
    </Toolbar>
  );
};

const styles = createStyles({
  toolbar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  loginBtn: {
    width: "160px",
    height: "33.5px",
    backgroundImage: `url(${LoginBtn})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    flexShrink: 0,
  },
  registerBtn: {
    width: "160px",
    height: "33.5px",
    backgroundImage: `url(${RegisterBtn})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    flexShrink: 0,
  },
});

export default TopBar;
