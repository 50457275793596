import { VisuallyHidden } from "@99tech/ui";
import { Box, Stack, SxProps, Typography } from "@mui/material";
import React, { MouseEvent, useCallback, useContext } from "react";
import {
  CharacterBg,
  CharacterFg,
  DownloadAndroid,
  DownloadApple,
  Header,
  LoginBtn,
  LogoHeader,
  LogoIcon,
  Payments,
  ProvAG,
  ProvAMB,
  ProvCQ9,
  ProvJK,
  ProvJL,
  ProvMic,
  ProvPG,
  ProvPrag,
  ProvSA,
  ProvSlo,
  ProvSpp,
  ProvSpx,
  ProvSta,
  RegisterBtn,
  TagLine,
} from "src/assets";
import { LineSupportButton } from "src/main/components";
import { AppContext } from "src/main/constants";
import useGtmTracker, { EvtAction } from "src/main/hooks/useGtmTracker";
import { createStyles, isMobile } from "src/main/utils";

interface LoginProps extends React.PropsWithChildren {}

interface LogoBoxProps {
  sx: SxProps | undefined;
  alt: string | undefined;
  path: string | undefined;
}
const LogoBox = (props: LogoBoxProps) => {
  const { sx = {}, alt = "logo", path } = props;
  return (
    <Box sx={sx}>
      <img
        alt={alt}
        src={path}
        style={{ width: "100%" }}
      />
    </Box>
  );
};

const LandingPage: React.FC<LoginProps> = (props) => {
  const { sendEvent } = useGtmTracker();
  const { urls } = useContext(AppContext);
  const { loginUrl, registerUrl } = urls ?? {};

  const handleClick = useCallback(
    (type: "login" | "register") => (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      if (type === "login") {
        sendEvent(EvtAction.ClickLogin);
        window.open(loginUrl, "_blank", isMobile() ? "width=full,height=full" : "");
      } else {
        sendEvent(EvtAction.ClickRegister);
        window.open(registerUrl, "_blank", isMobile() ? "width=full,height=full" : "");
      }
    },
    [loginUrl, registerUrl, sendEvent],
  );
  return (
    <Box
      maxWidth="sm"
      sx={{
        padding: "8px 16px 64px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "auto",
        overflowY: "auto",
      }}
    >
      <VisuallyHidden as="h1">wow268</VisuallyHidden>
      <Stack sx={brandStyles.root}>
        <Box sx={brandStyles.header}></Box>
        <Box sx={brandStyles.logo}></Box>
        <Box sx={brandStyles.tagline}></Box>
      </Stack>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          gap: 1,
          justifyContent: "center",
          pt: 2,
          width: "100%",
        }}
      >
        <Box
          component="a"
          href={loginUrl}
          target="_blank"
          onClick={handleClick("login")}
          sx={buttonStyles.loginBtn}
        />
        <Box
          component="a"
          href={registerUrl}
          target="_blank"
          onClick={handleClick("register")}
          sx={buttonStyles.registerBtn}
        />
      </Box>
      <Box sx={characterStyles.root}>
        <Box className="place-holder"></Box>
        <Box sx={characterStyles.bg}></Box>
        <Box sx={characterStyles.main}></Box>
      </Box>

      <Box paddingY="32px">
        <Typography
          sx={textStyles.normal}
          textAlign="center"
        >
          หากคุณกำลังมองหาประสบการณ์การเล่นเกมสล็อตที่ตื่นเต้นและรายได้สูง ลองเปิดใจมาเล่นกับ wow268.com! ที่ wow268
        </Typography>
        <Typography
          sx={textStyles.normal}
          textAlign="center"
        >
          เราให้บริการแพลตฟอร์มการเล่นสล็อตออนไลน์ชั้นนำที่จะทำให้คุณทั้งมีความสนุก ตื่นเต้น และรายได้ที่มหาศาล
        </Typography>
      </Box>
      <Typography
        sx={textStyles.title}
        textAlign="center"
        color="#FFB23E"
        paddingBottom="4px"
      >
        เลือกเล่นเกมสล็อต เลือก wow268.com
      </Typography>
      <Box
        paddingBottom="32px"
        textAlign="center"
      >
        <Typography sx={textStyles.small}>
          wow268.com มีเกมสล็อตหลากหลายจากค่ายชั้นนำ คอลเลกชันของเกมสล็อตที่ wow268.com
          มีการออกแบบมาเพื่อใช้สอดส่องทุกประเภทของผู้เล่น
        </Typography>
        <Typography sx={textStyles.small}>
          ไม่ว่าคุณจะชอบสล็อตแบบผลไม้คลาสสิกหรือเกมสล็อตวิดีโอทันสมัย wow268 มีสิ่งที่คุณต้องการ wow268.com
          มีอินเตอร์เฟซที่ใช้งานง่าย การนำทางเว็บไซต์ของ wow268.com เป็นเรื่องง่าย
        </Typography>
        <Typography sx={textStyles.small}>
          ทำให้ผู้เล่นใหม่สามารถหาเกมที่ชอบได้อย่างรวดเร็วและเริ่มเล่นได้ทันที wow268.com
          มีโบนัสและโปรโมชั่นที่น่าตื่นเต้น wow268 เชื่อในการแต่งแต้มผู้เล่นของ wow268 อย่างดี
        </Typography>
        <Typography sx={textStyles.small}>
          เพลิดเพลินกับโบนัสและโปรโมชั่นที่ยอดเยี่ยมที่เพิ่มโอกาสให้คุณชนะมากขึ้น wow268.com มีระบบการเล่นเป็นธรรม ที่
          wow268 ความปลอดภัยของคุณเป็นสิ่งสำคัญสูงสุดสำหรับเรา wow268
          ใช้มาตรการรักษาความปลอดภัยขั้นสูงและให้โอกาสในการเล่นเป็นธรรมแก่ผู้เล่นทุกคน และ wow268.com
          มาพร้อมค่ายเกมสล็อตชั้นนำมากมาย อาทิเช่น
        </Typography>
      </Box>
      <Stack
        direction="row"
        width="100%"
      >
        <LogoBox
          alt="brand logo"
          path={ProvPG}
          sx={{ flex: 1 }}
        />
        <LogoBox
          alt="brand logo"
          path={ProvJK}
          sx={{ flex: 1 }}
        />
        <LogoBox
          alt="brand logo"
          path={ProvJL}
          sx={{ flex: 1 }}
        />
        <LogoBox
          alt="brand logo"
          path={ProvAG}
          sx={{ flex: 1 }}
        />
        <LogoBox
          alt="brand logo"
          path={ProvCQ9}
          sx={{ flex: 1 }}
        />
      </Stack>
      <Stack
        direction="row"
        width="100%"
      >
        <LogoBox
          alt="brand logo"
          path={ProvAMB}
          sx={{ flex: 1 }}
        />
        <LogoBox
          alt="brand logo"
          path={ProvPrag}
          sx={{ flex: 1 }}
        />
        <LogoBox
          alt="brand logo"
          path={ProvMic}
          sx={{ flex: 1 }}
        />
        <LogoBox
          alt="brand logo"
          path={ProvSpx}
          sx={{ flex: 1 }}
        />
        <LogoBox
          alt="brand logo"
          path={ProvSpp}
          sx={{ flex: 1 }}
        />
      </Stack>
      <Stack
        direction="row"
        width="100%"
      >
        <Box sx={{ flex: 1 }} />
        <LogoBox
          alt="brand logo"
          path={ProvSta}
          sx={{ flex: 1 }}
        />
        <LogoBox
          alt="brand logo"
          path={ProvSlo}
          sx={{ flex: 1 }}
        />
        <LogoBox
          alt="brand logo"
          path={ProvSA}
          sx={{ flex: 1 }}
        />
        <Box sx={{ flex: 1 }} />
      </Stack>
      <Typography
        sx={textStyles.small}
        textAlign="center"
        paddingTop="32px"
      >
        สมัครสมาชิกกับ wow268.com วันนี้และเข้าร่วมโลกของการพนันสล็อตออนไลน์ แพลตฟอร์ม wow268 ของเราคือสถานที่
        ที่สมบูรณ์แบบในการลุ้นโชคสำหรับทุกคน wow268.com มุ่งเสนอความพึงพอใจและความสำเร็จของคุณเป็นอันดับหนึ่ง
        มาเริ่มปั่นกันเลยที่ wow268.com และสัมผัสความตื่นเต้นของการชนะครั้งใหญ่!
      </Typography>
      <Typography
        sx={textStyles.title}
        textAlign="center"
        paddingY="16px"
        color="white"
      >
        WOW268.com เว็บตรง ลิขสิทธิ์แท้
      </Typography>
      <Box
        sx={{
          width: "212.36px",
          height: "113.2px",
          backgroundImage: `url(${LogoIcon})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          flexShrink: 0,
        }}
      />
      <Typography
        sx={textStyles.small}
        textAlign="center"
        paddingTop="32px"
      >
        หากคุณมีคำถามหรือต้องการความช่วยเหลือ ฝ่ายลูกค้าสัมพันธ์ของ wow268 ยินดีบริการ 24 ชั่วโมง เพื่อช่วยเหลือคุณ
        wow268.com สามารถเล่นได้บนมือถือ
        ช่วยให้คุณเล่นเกมสล็อตที่คุณชื่นชอบได้ทุกที่กับแพลตฟอร์มที่เหมาะสมกับมือถือของคุณ ที่ wow268
        คุณจะเพลิดเพลินกับประสบการณ์การเล่นที่มีคุณภาพสูง รวมถึงระบบฝากถอนที่รวดเร็วทันใจ
      </Typography>
      <Box
        sx={{
          width: "100%",
          height: "50px",
          backgroundImage: `url(${Payments})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          flexShrink: 0,
          marginY: "32px",
          alignSelf: "center",
        }}
      />
      <Stack
        direction="row"
        width="100%"
        justifyContent="space-around"
        gap={2}
      >
        <Box
          sx={{
            width: "50%",
            maxWidth: "151.63px",
            height: "44.92px",
            backgroundImage: `url(${DownloadApple})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            flexShrink: 0,
          }}
        />
        <Box
          sx={{
            width: "50%",
            maxWidth: "151.63px",
            height: "44.92px",
            backgroundImage: `url(${DownloadAndroid})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            flexShrink: 0,
          }}
        />
      </Stack>
      <LineSupportButton />
    </Box>
  );
};

const brandStyles = createStyles({
  root: {
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "266px",
  },

  header: {
    width: "216.8px",
    height: "82.2px",
    backgroundImage: `url(${Header})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    flexShrink: 0,
  },
  logo: {
    width: "100%",
    maxWidth: "324.9px",
    height: "160px",
    backgroundImage: `url(${LogoHeader})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    flexShrink: 0,
    transform: `translateY(-12px)`,
  },
  tagline: {
    width: "261.4px",
    height: "47.9px",
    backgroundImage: `url(${TagLine})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    flexShrink: 0,
    transform: `translateY(-22px)`,
  },
});

const characterStyles = createStyles({
  root: {
    width: "100%",
    maxWidth: "341.7px",
    height: "363.5px",
    position: "relative",

    ".place-holder": {
      width: "100%",
      maxWidth: "341.7px",
      height: "363.5px",
      backgroundColor: "rgba(0,0,0,0)",
    },
  },
  bg: {
    width: "100%",
    maxWidth: "341.7px",
    height: "269.7px",
    position: "absolute",
    backgroundImage: `url(${CharacterBg})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    flexShrink: 0,
    zIndex: 0,
    bottom: "28.38px",
    animation: "jumpy 1.6s infinite",
    animationTimingFunction: "linear",
  },
  main: {
    top: 0,
    left: "18.35px",
    width: "100%",
    maxWidth: "305px",
    height: "363.5px",
    position: "absolute",
    zIndex: 1,
    backgroundImage: `url(${CharacterFg})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    flexShrink: 0,
  },
});

const textStyles = createStyles({
  normal: {
    color: "white",
    fontFamily: "Kanit",
    fontSize: "12px",
    fontWeight: "400",
  },
  title: {
    fontFamily: "Kanit",
    fontSize: "16px",
    fontWeight: "600",
  },
  small: {
    color: "white",
    fontFamily: "Kanit",
    fontSize: "10px",
    fontWeight: "400",
  },
});

const buttonStyles = createStyles({
  loginBtn: {
    backgroundImage: `url(${LoginBtn})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    height: "max(6vh, 54px)",
    width: "max(19vh, 165px)",
    maxWidth: "cal(50% - 32px)",
    cursor: "pointer",
    overflowX: "hidden",
  },
  registerBtn: {
    backgroundImage: `url(${RegisterBtn})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    height: "max(6vh, 54px)",
    width: "max(19vh, 165px)",
    maxWidth: "cal(50% - 32px)",
    cursor: "pointer",
  },
});

export default LandingPage;
