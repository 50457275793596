import BigNumber from "bignumber.js";

export const BN_ZERO = new BigNumber(0);
export const BN_ONE = new BigNumber(1);

export const Paths = {
  Landing: {
    Index: "/",
  },
};

export const LoginUrl = "https://m.woww268.com/";

export const PUSH_NOTIFICATION_STORAGE_KEY = "push-notification-register-status";
export const REGISTERED = "REGISTERED";

export const BTN_LOGIN_URL = "https://m.woww268.com/";
export const BTN_REGISTER_URL = "https://m.woww268.com/?action=register";

export const LineSupportLink = "https://wow268win.com/cs";

export const DOMAIN_URL_MAP = {
  "wow268.shop": {
    login: "https://a.vtrk8.com/4b691249-21d9-4d5e-a2c1-e2983982af87",
    register: "https://a.vtrk8.com/88caecd0-b7a8-4dc6-ac82-945400735999",
  },
};

export * from "./codes";
export * from "./context";
